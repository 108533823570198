.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
}