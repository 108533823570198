.siteHeader {
  background-color: #f8f9fa;
  padding: 0px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.logoLink {
  display: inline-block;
}



@media (max-width: 768px) {
  .siteHeader {
    text-align: center;
  }
  
 
}